import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from '../services/restful.service';
import { APPCONFIG } from '../../app.config';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import * as ENDPOINT from '../../shared/constant/endPoint.js';

import SimulteAPI from '../../../assets/server/simulate_api.json';
import { RequestBody, Page1Info, Page2Info } from '../interfaces/landing.interface';

@Injectable()
export class LandingPageService {

  page1Info: Page1Info;
  page2Info: Page2Info;

  temp_resultPayment: boolean;

  constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }

  setPage1Info(info: Page1Info): void {
    this.page1Info = info;
  }

  getPage1Info(): Page1Info {
    return this.page1Info;
  }

  setPage2Info(info: Page2Info): void {
    this.page2Info = info;
  }

  getPage2Info(): Page2Info {
    return this.page2Info;
  }


  temp_getResellerInfo(idReseller: string) {

    return SimulteAPI.resellers[idReseller].info;
  }

  temp_getPriceList(idReseller: string) {
    return SimulteAPI.resellers[idReseller].priceList;
  }
  temp_getProductFamily(idReseller: string) {
    return SimulteAPI.resellers[idReseller].productFamily;
  }

  temp_getProductListGroup(idReseller: string, currency: string) {
    return SimulteAPI.resellers[idReseller].productListGroup[currency];
  }

  temp_setResulPayment(result: boolean) {
    this.temp_resultPayment = result;
  }

  temp_getResulPayment(): boolean {
    return this.temp_resultPayment;
  }


  getResellerLandingInfo(idReseller: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.reseller_landingInfo, idReseller);
    return this.get(endPoint);
  }

  getPriceListReseller(idReseller: string, companyCode): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.priceList_reseller, idReseller, companyCode);
    return this.get(endPoint);
  }

  getProductFamily(idReseller: string, companyCode: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.order_productFamily, idReseller, companyCode);
    return this.get(endPoint);
  }

  getProductListGroup(idReseller: string, companyCode: string, currency: string): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_productListGroup,
      idReseller, companyCode, currency, APPCONFIG.currentLanguage);
    return this.get(endPoint);
  }

  getTermAndCondition(idProduct: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.product_termsAndCondition, idProduct);
    return this.get(endPoint);
  }



  onApplyCoupon(reqBody: RequestBody): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.order_orderSummary, APPCONFIG.currentLanguage);
    return this.post(endPoint, reqBody);
  }

  // tolto il tipo reqBody TEMPORANEAMENTE, IN ATTESA DI RACHID
  saveCustomerOrder(reqBody: any): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.order_customerOrder);
    return this.post(endPoint, reqBody);
  }

  attemptedPayment(reqBody: any): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.order_attemptedPayment, APPCONFIG.currentLanguage);
    return this.post(endPoint, reqBody);
  }

  // NON CREDO VENGA UTILIZZATA
  getServiceList(): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.order_orderService, APPCONFIG.currentLanguage);
    return this.get(endPoint);
  }

  // NON CREDO VENGA UTILIZZATA
  onPaymentSuccess(reqBody: RequestBody): Observable<any> {
    // Perchè la lingua viene impostata in reqBody ?
    reqBody['languageCode'] = APPCONFIG.currentLanguage;
    const endPoint = ENDPOINT.order_paymentSuccess;
    // migliorare...perchè la gestione della risposta è in page1
    return this.restful.post(endPoint, reqBody);
  }

  // NON CREDO VENGA UTILIZZATA
  getPaymentSummary(ids: any): Observable<any> {
    let query1 = '';
    if (ids && ids.length > 0) {
      query1 = '?';
      for (const r in ids) {
        query1 += `orderIds=${ids[r]}${((ids.length - 1) != Number(r)) ? '&' : ''}`;
      }
    }
    const endPoint = getEndPointWithParams(ENDPOINT.order_purchaseSummary, APPCONFIG.currentLanguage);
    return this.restful.get(endPoint + query1);
  }


  post(endPoint: string, reqBody: RequestBody): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    const dataResponse = response['data'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(dataResponse);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}


export enum UserAction {
  NEW_USER = "newUser",
  EDIT_USER = "editUser",
  DELETE_USER = "deleteUser"
}

export enum CodeRole {
  COMPANY_ADMIN = "COMPANY_ADMIN",
  RESELLER_ADMIN = "RESELLER_ADMIN",
  RESELLER_AGENT = "RESELLER_AGENT",
  SUPERADMIN = "SUPERADMIN"
}


export interface UserInfo {
  idUser?: number;
  username?: string;
  descRole?: string;
  name: string;
  surname: string;
  email: string;
  codeRole: string;
  enabled: boolean;
  idCompany: number;
  idReseller: number;
  canBeEditable?: boolean;
  canBeDeleted?: boolean;
}

export interface UserSpesotto {
  idUser: number;
  username: string;
  name: string;
  surname: string;
  email: string;
  codeRole: string;
  enabled: boolean;
  idCompany: number;
  idReseller: number;
  businessName: string;
}
